import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    user:{
        name:'',
        avatar:'',
        token: '',
    },
}

// 实时监听 state 值 的最新状态，注意这里的getters 可以理解为计算属性
const getters = {
    // 在组件中是通过 this.$store.getters.getUser 来获取
    getUser(state){
        return state.user
    }
}

// 定义改变state初始值的方法，这里是唯一可以改变state的地方，缺点是只能同步执行
const mutations = {
    // 在组件中是通过 this.$store.commit('updateUser',user); 方法来调用mutations
    updateUser(state,user){
        state.user = user;
    }
};

// 定义触发 mutations 里函数的方法，可以异步执行 mutations 里的函数
const actions = {
    // 在组件中是通过 this.$store.dispatch('asyncUpDateUser',user); 来调用 actions
    asyncUpDateUser(context,user) {
        context.commit('updateUser',user);
    }
};

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
});