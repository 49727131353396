<template>
  <div id="app">
    <!-- 在你的HTML文件中添加这个meta标签，以确保正确的viewport设置 -->
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <router-view></router-view>
  </div>
</template> 

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    // 设置根元素的字体大小，可以根据需要进行调整
    this.setRootFontSize();
    // 监听窗口大小变化，动态调整字体大小
    window.addEventListener("resize", this.setRootFontSize);
  },
  methods: {
    setRootFontSize() {
      // 根据视口宽度设置字体大小，可以根据需要进行调整
      const baseSize = window.innerWidth / 1920 * 16; // 1rem等于视口宽度的1/10
      document.documentElement.style.fontSize = `${baseSize}px`;
    },
  },
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
}
#app {
  overflow: hidden;
  overflow-y: hidden;
  p,
  ul,
  a {
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
  }
}
</style>
