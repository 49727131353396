import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
    redirect: '/AI-Lianchuang',
    children: [{
        path: "/AI-Lianchuang",
        name: "AI-Lianchuang",
        component: () => import('@/views/index/AI-Lianchuang.vue'),
        children: [{
          path: "",
          components: { //首页的组件
            // default: Foo,
            anniu: () => import('@/views/index/mod/ideaBuddy/assist/assist.vue'), //按钮切换图片
            zhi: () => import('@/views/index/mod/ideaBuddy/custom/custom.vue'),//自定以模版
            // b: Baz
          },
        }]
      },
      {
        path: '/Idea_Plan',
        name: 'Idea_Plan',
        component: () => import('@/views/index/Idea_Plan.vue'),
      },
      {
        path: "/Whiteboard",
        name: "Whiteboard",
        component: () => import('@/views/index/Whiteboard.vue'),
      },
      {
        path: '/Business_Guide',
        name: 'Business_Guide',
        component: () => import('@/views/index/Business_Guide.vue'),
      },
      {
        path: '/Financial_Plan',
        name: 'Financial_Plan',
        component: () => import('@/views/index/Financial_Plan.vue'),
      },
      {
        path: '/Idea_Validation',
        name: 'Idea_Validation',
        component: () => import('@/views/index/Idea_Validation.vue'),
      },
      {
        path: '/Business_Plan',
        name: 'Business_Plan',
        component: () => import('@/views/index/Business_Plan.vue'),
      },
      {
        path: '/Pricing',
        name: 'Pricing',
        component: () => import('@/views/index/Pricing.vue'),
      },
      {
        path: '/Entrpreneurs & Startups',
        name: 'Entrpreneurs & Startups',
        component: () => import('@/views/index/Entrpreneurs & Startups.vue'),
      },
      {
        path: '/Incubators & Accelertors',
        name: 'Incubators & Accelertors',
        component: () => import('@/views/index/Incubators & Accelertors.vue'),
      },
      {
        path: '/Business_Schools',
        name: 'Business_Schools',
        component: () => import('@/views/index/Business_Schools.vue'),
      },
      {
        path: '/Blog',
        name: 'Blog',
        component: () => import('@/views/index/Blog.vue'),
      },
      {
        path: '/Help_Center',
        name: 'Help_Center',
        component: () => import('@/views/index/Help_Center.vue'),
      }

    ]
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/user.vue'),
    redirect: '/user/Home',
    children: [{
        //家
        path: '/user/Home',
        name: 'Home',
        component: () => import('@/views/user/Home.vue'),
      },
      {
        //创意计划
        path: '/user/Idea_Plan',
        name: 'Idea_Plan',
        component: () => import('@/views/user/Idea_Plan.vue'),
      },
      {
        //业务指南
        path: '/user/Business_Guide',
        name: 'Business_Guide',
        component: () => import('@/views/user/Business_Guide.vue'),
      },
      {
        //创意评分
        path: '/user/Idea_Score',
        name: 'Idea_Score',
        component: () => import('@/views/user/Idea_Score.vue'),
      },
      {
        //财务计划
        path: "/user/Financial_Plan",
        name: "Financial_Plan",
        component: () => import('@/views/user/Financial_Plan.vue'),
      },
      {
        //商业计划书
        path: '/user/Business_Plan',
        name: 'Business_Plan',
        component: () => import('@/views/user/Business_Plan.vue'),
      },
      {
        //白板
        path: '/user/Whiteboard',
        name: 'Whiteboard',
        component: () => import('@/views/user/Whiteboard.vue'),
      },

      {
        //创意合集
        path: '/user/Idea_Collection',
        name: 'Idea_Collection',
        component: () => import('@/views/user/Idea_Collection.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router