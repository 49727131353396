import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import axios from './utils/request'
import ElementUI from 'element-ui';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import './on-qy/iconfont.css'
// import './utils/rem.js'

// 全局注册组件
Vue.use(ElementUI);
Vue.prototype.axios = axios;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
